<template>
  <div>
    <div class="d-flex">
      <div
        name="деньги по региону"
        class="rounded-bl-lg text-right"
        style="border-bottom: 1px solid #E0E0E0; width: 7em; padding-right: 1em"
      >
        {{ fact | money }}<span class="Sign"> ₽</span>
      </div>
      <div
        name="выполнение по региону"
        class="text-center text-16"
        style="border-bottom: 1px solid #E0E0E0; width: 4em"
      >
        {{ plan ? ((fact / plan) * 100).toFixed() : 0
        }}<span class="Sign">%</span>
      </div>
      <div
        style="border-bottom: 1px solid #E0E0E0; width: 4em; padding-right: 1em"
        class="text-right text-16"
        name="прогноз по региону"
        :class="
          prognoz < 90 ? 'redLight--text' : prognoz >= 100 ? 'green--text' : ''
        "
      >
        {{ prognoz.toFixed() }}<span class="Sign">%</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SumRegion",
  props: {
    progress: { type: Boolean, required: false, default: true },
    prognoz: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
    plan: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
    fact: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    }
  },
  data: () => ({})
};
</script>

<style scoped>
.Sign {
  opacity: 0.4;
}
</style>
