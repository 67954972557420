<template>
  <v-text-field
    hide-details
    filled
    rounded
    dense
    :id="key"
    :clearable="focus ? true : false"
    class="rounded-lg"
    color="orange"
    v-model="number"
    :label="label"
    :append-outer-icon="focus ? 'mdi-check-bold' : ''"
    clear-icon="mdi-close-circle"
    @click:append-outer="returnValue"
  ></v-text-field>
</template>
<script>

export default {
  name: "TextField",

  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
  },

  data: () => ({
    number: null,
    key: String(Math.random().toFixed(10)),
    focus: false,
    test: false
  }),
  watch: {},
  methods: {
    del_focus () {
      this.focus = false;
    },
    add_focus () {
      this.focus = true;
    },
    clearFiled() {},
    returnValue() {},
  },
  created() {
    setTimeout(() => {
      document.getElementById(this.key).addEventListener("focus", this.add_focus);
      document.getElementById(this.key).addEventListener("blur", this.del_focus);
    }, 200)
  },
};
</script>

<style scoped></style>
