<template>
  <v-card
    width="200"
    name="цифры по всем магазинам"
    class="text-16"
    style="border-radius: 16px; opacity: 1"
  >
    <div class="d-flex justify-center">
      <div
        class="text-right pr-1 rounded-r-0 grey7"
        style="width: 50%; border-radius: 16px"
      >
        <div>
          {{ plan > 0 ? ((fact / plan) * 100).toFixed(1) : 0 | money
          }}<span class="Sign">%</span>
        </div>
        <div>{{ fact | moneyMln }}<span class="Sign"> млн</span></div>
      </div>
      <div
        class="d-flex grey3 white--text rounded-l-0"
        style="width: 50%; border-radius: 16px"
      >
        <div class="Sign Rotate ml-1 mr-n4">план</div>
        <div>
          <div
            :class="
              (prognoz / plan) * 100 < 90
                ? 'redLight--text'
                : (prognoz / plan) * 100 >= 100
                ? 'greenLight--text'
                : ''
            "
          >
            {{ ((prognoz / plan) * 100) | money }}<span class="Sign">%</span>
          </div>
          <div>{{ plan | moneyMln }}<span class="Sign"> млн</span></div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SumTopComponent",
  props: {
    progress: { type: Boolean, required: false, default: true },
    prognoz: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
    plan: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    },
    fact: {
      type: Number,
      required: false,
      default: 0,
      validator: v => typeof v === "number"
    }
  },
  data: () => ({})
};
</script>

<style scoped>
.Sign {
  opacity: 0.4;
}
.Rotate {
  transform: rotate(-90deg);
}
</style>
