<template>
  <div>
    <div v-if="!opend" name="заголовок закрытый" class="grey6 rounded-lg pa-1">
      <div class="d-flex">
        <ShopCheap :shop="data.ID" color="orangeDark" class="mx-1 Shop ml-4" />
        <div class="grey2--text text-10">{{ data.name }}</div>
        <v-spacer> </v-spacer>
        <div name="деньги" class="d-flex">
          <div style="width: 6em" class="text-right">
            <div class="grey2--text text-10">
              {{ data.money | money }}<span class="Sign"> ₽</span>
            </div>
            <div
              class="mt-n1 grey--text text-right font-weigt-light"
              v-if="data.correct"
            >
              {{ data.correct > 0 ? "+" : "-" }}
              {{ data.correct > 0 ? data.correct : -data.correct | money }}
            </div>
          </div>
          <div class="mx-2 ml-3 grey5--text">|</div>
          <div style="width: 3em" class="text-right">
            {{ data.moneypercent | money }}<span class="Sign">%</span>
          </div>
          <div class="mx-2 grey5--text">|</div>
          <div
            style="width: 3em"
            class="text-right pr-1"
            :class="
              data.prognoz < 90
                ? 'redLight--text'
                : data.prognoz >= 100
                ? 'green--text'
                : ''
            "
          >
            {{ data.prognoz | money }}<span class="Sign">%</span>
          </div>
        </div>
      </div>
      <!-- <div class="text-9">
           {{ data }}
           </div> -->
    </div>
    <div v-else class="pb-10">
      <div
        name="заголовок открытый"
        class="grey5 rounded-lg pa-1 mb-3"
        style="height: 2em"
      >
        <div class="d-flex">
          <ShopCheap
            :shop="data.ID"
            size="38"
            text_size="22"
            color="orangeDark"
            class="mx-1 ml-4"
          />
          <div class="grey2--text text-h5" style="margin-top: -4px">
            {{ data.name }}
          </div>
          <v-spacer />
          <v-spacer />
          <div class="grey2--text">
            {{ data.space }} <span>м</span><sup>2</sup>
          </div>
        </div>
      </div>
      <div name="планы" class="d-flex flex-wrap px-1">
        <TextField
          :value="null"
          label="планы на месяц"
          style="width: 40%"
          class="ma-1"
        />
        <v-btn elevation="1" x-large style="width: 20%" class="ma-1 rounded-lg">
          <v-icon class="grey--text">mdi-pencil</v-icon>
        </v-btn>
        <v-btn elevation="1" x-large style="width: 20%" class="ma-1 rounded-lg">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <TextField
          :value="null"
          label="точная выручка"
          style="width: 40%"
          class="ma-1"
        />
        <TextField
          :value="null"
          label="точка безубыточности"
          style="width: 40%"
          class="ma-1"
        />
        <TextField
          :value="null"
          label="планы на месяц"
          style="width: 40%"
          class="ma-1"
        />
      </div>
      <div name="сотрудники"></div>
    </div>
  </div>
</template>

<script>
import ShopCheap from "./ShopCheapComponent";
import TextField from "./TextFieldComponent";
export default {
  name: "ShopComponent",
  components: {
    ShopCheap,
    TextField,
  },
  props: {
    opend: {
      type: Boolean,
      required: false,
      default: false,
    },
    data: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    plan: "",
  }),
};
</script>

<style scoped>
.Sign {
  opacity: 0.4;
}
.Shop {
  opacity: 0.6;
}
</style>
